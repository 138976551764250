import { ReactComponent as ControlDLogo } from 'assets/controld-logo-small.svg'
import { ReactComponent as MobileHeaderClose } from 'assets/mobile-header-close.svg'
import { ReactComponent as BarryButtonIcon } from 'assets/barry-button-icon.svg'
import { ReactComponent as PurpleEllipsis } from 'assets/purple-ellipsis.svg'
import SplashScreen from 'components//SplashScreen'
import SubmitTicketView from 'components/SubmitTicketView'
import TicketSent from 'components/TicketSent'
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { endChat, messageActions } from 'redux/slices/message'
import { Page, pageActions } from 'redux/slices/page'
import { Flex, IconButton } from 'theme-ui'
import BarryChatScreen from './BarryChatScreen'
import { getBarryDisplayBoolean, getSessionToken } from 'utils/localStorage'
import { getTime } from 'utils/time'

// this syntax is required to get style-loader and css-loader working to bundle the CSS
// with the main JS file
// see https://github.com/storybookjs/presets/issues/191#issuecomment-812094476 for details
import '!style-loader!css-loader!global.css'

import DescribeYourIssue from 'components/DescribeYourIssue'
import useBreakpointIndex from 'theme/useBreakpointIndex'
import { barryApi } from 'redux/api/barryApi'
import BarryPopUp, { BarryHelloMessagAction } from './BarryPopUp'
import { cdApi } from 'redux/api/cdApi'
import { wobble } from 'utils/animations'
import { differenceInMonths } from 'date-fns'
import { userExperienceActions } from 'redux/slices/userExperience'

const toggleButtonSize = '48px'
const mobileHeaderHeight = '72px'
const zIndex = 99999
const goodByeMessage =
  'I see that you logged out. Thank you for chatting with me today! Feel free to return whenever you have more questions about Control D or need further assistance. Goodbye and take care!'

enum BarryQueryStringActions {
  WAKEUP = 'wakeup',
  START = 'start',
}
export default function MainLayout(): ReactElement {
  const dispatch = useAppDispatch()
  const breakpointIndex = useBreakpointIndex()
  const isMobile = breakpointIndex === 0
  const isBarryOverlapping = breakpointIndex === 3 || isMobile
  const pathname = document.URL
  const pathnameRef = useRef(pathname)
  const isDashboard = pathname.includes('dashboard')
  const { page } = useAppSelector(state => state.page)
  const { chatEndsAt, messages } = useAppSelector(x => x.message)
  const { conversationId } = useAppSelector(x => x.message)
  const [shouldAllowBarryIconOnDashboard, setShouldAllowBarryIconOnDashboard] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isTabVisible, setIsTabVisible] = useState(true)
  const [showBarryHelloMessage, setShowBarryHelloMessage] = useState(false)
  const [previousDocumentUrl, setPreviousDocumentUrl] = useState(pathname)
  const [previousSessionToken, setPreviousSessionToken] = useState('')
  const [endConversationRequest] = barryApi.useEndConversationMutation()
  const [hasUserJustLoggedOut, setHasUserJustLoggedOut] = useState(false)
  const { chatBubbleBeenClosedOn } = useAppSelector(x => x.userExperience)
  const [allowOpenFromQueryString, setAllowOpenFromQueryString] = useState(true)
  const shouldFetchBubbleMessage = chatBubbleBeenClosedOn
    ? differenceInMonths(new Date(), new Date(chatBubbleBeenClosedOn)) >= 1
    : true
  const {
    data: barryHelloMessage,
    isSuccess: isBarryHelloMessageSuccess,
    isLoading: isBarryHelloMessageLoading,
  } = cdApi.useGetHelloBarryMessageQuery({ on_page: pathname }, { skip: !shouldFetchBubbleMessage })

  /**
   * TODO: Make Utility Function.
   * Determines the opacity of Barry based on various conditions including whether it's the dashboard, mobile view, and Barry's overlapping state.
   * @returns an opacity value
   */
  const getOpacity = (
    isDashboard: boolean,
    isBarryOverlapping: boolean,
    shouldAllowBarryIconOnDashboard: boolean,
    isMobile: boolean,
  ) => {
    if (!isDashboard) {
      return 1
    }
    if (isMobile && isOpen) {
      return 0
    }
    if (isBarryOverlapping) {
      return shouldAllowBarryIconOnDashboard ? 1 : 0
    }
    return 1
  }

  const shouldShowBarryPopUp =
    !!getOpacity(isDashboard, isBarryOverlapping, shouldAllowBarryIconOnDashboard, isMobile) &&
    showBarryHelloMessage &&
    !!barryHelloMessage &&
    !isBarryHelloMessageLoading &&
    !isOpen
  useEffect(() => {
    if (isBarryOverlapping && isOpen && !shouldAllowBarryIconOnDashboard) {
      setShouldAllowBarryIconOnDashboard(true)
    }
  }, [breakpointIndex, isBarryOverlapping, isOpen, shouldAllowBarryIconOnDashboard])

  useEffect(() => {
    const currentSessionToken = getSessionToken()
    const documentUrl = document.URL

    if (currentSessionToken && documentUrl !== pathnameRef.current && showBarryHelloMessage) {
      dispatch(userExperienceActions.setChatBubbleBeenClosedOn(Date.now()))
    }
  }, [pathnameRef, dispatch, showBarryHelloMessage])

  /**
   * Hacky Fix for implementing #77, in CD we can just subscribe to the qs and toggle barry accordingly.
   * The issue is that we currently open barry by "signaling" it through the localStorage through CD.
   * We're therfore polling every few milliseconds if this has changed, and toggle accordingly. But if we want to toggle
   * from the querystring, we need a way to do this only once, because otherwise the other polling attempts will reopen barry if the
   * ?barry=wakeup or ?barry=start is still present. Once barry is integrated in CD, we can just use a hook that's dependent on qs.
   */
  const openBarryFromQueryStrings = useCallback((): void => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const barryAction = urlParams.get('barry')
    const shouldStartBarry = barryAction === BarryQueryStringActions.START
    const shouldWakeupBarry = barryAction === BarryQueryStringActions.WAKEUP

    if (allowOpenFromQueryString && (shouldStartBarry || shouldWakeupBarry)) {
      if (shouldStartBarry) {
        if (!!messages) {
          dispatch(endChat())
          endConversationRequest({ conversationId })
        }
        dispatch(pageActions.switchPage(Page.DESCRIBE_ISSUE_PAGE))
      }
      if (shouldWakeupBarry && page === Page.DESCRIBE_ISSUE_PAGE) {
        dispatch(pageActions.switchPage(Page.SPLASH_PAGE))
      }
      setIsOpen(true)
      setAllowOpenFromQueryString(false)
      setShouldAllowBarryIconOnDashboard(true)
    }
  }, [dispatch, endConversationRequest, conversationId, allowOpenFromQueryString, messages, page])

  // TODO: FIX THIS. This does not need to be a polled fuction. You should be able to get
  // the path of the current page from the pathname and use a ref to store it and compare
  // the old path in the ref to the current path.
  // ADDENDUM: Barry is currently being integrated into CD Codebase because the <Script/> tag barry was referenced in before did not
  // allow for synchronized updates with the CD Code, hence the need for the polling below.
  // As per https://gitlab.int.windscribe.com/controld/frontend/website/-/issues/4355 Barry is currently being integrated into the CD codebase,
  // meaning the checkLocationChange polling and other functions/variables that depend on polling will be replaced in the process.
  const checkLocationChange = useCallback(() => {
    const currentSessionToken = getSessionToken()
    const documentUrl = document.URL
    const currentContainsDashboard = documentUrl.includes('dashboard')
    const previousContainsDashboard = previousDocumentUrl.includes('dashboard')

    if (currentSessionToken !== previousSessionToken) {
      if (previousSessionToken !== '' && currentSessionToken === '') {
        setHasUserJustLoggedOut(true)
        dispatch(endChat())
        endConversationRequest({ conversationId })
        dispatch(
          messageActions.addMessage({
            content: goodByeMessage,
            fromAgent: false,
            fromBarry: true,
            sender: 'Barry',
            timestamp: Math.floor(Date.now() / 1000),
            time: getTime(),
          }),
        )
        if (isBarryOverlapping) {
          setShouldAllowBarryIconOnDashboard(true)
        }
      }
      setPreviousSessionToken(currentSessionToken)
    }

    //Page has changed
    if (documentUrl !== previousDocumentUrl) {
      setAllowOpenFromQueryString(true)
      if (!previousContainsDashboard && currentContainsDashboard) {
        if (isBarryOverlapping) {
          setIsOpen(false)
          setShouldAllowBarryIconOnDashboard(false)
        }
        if (previousContainsDashboard && !currentContainsDashboard) {
          if (isBarryOverlapping) {
            setShouldAllowBarryIconOnDashboard(true)
          }
        }
      }
      setPreviousDocumentUrl(documentUrl)
    }
    openBarryFromQueryStrings()
  }, [
    previousDocumentUrl,
    previousSessionToken,
    dispatch,
    endConversationRequest,
    conversationId,
    isBarryOverlapping,
    openBarryFromQueryStrings,
    setAllowOpenFromQueryString,
  ])

  /**
   * We only want to display the chat bubble 3 seconds after we fetch a new message for the popup message.
   */
  const timeoutId = useRef<NodeJS.Timeout | undefined>()
  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    if (isBarryHelloMessageSuccess && !isOpen) {
      setShowBarryHelloMessage(false)
      timeoutId.current = setTimeout(() => {
        setShowBarryHelloMessage(true)
      }, 3000)
    }

    return () => clearTimeout(timeoutId.current)
  }, [isBarryHelloMessageSuccess, isOpen, barryHelloMessage])

  /**
   * When wanting to open Barry from CD, we signal this for one second by setting the relevant field
   * in local Storage to true, so we are polling this state from barry
   */
  const checkBarryDisplay = useCallback(() => {
    const barryToggle = getBarryDisplayBoolean()

    if (barryToggle) {
      if (shouldShowBarryPopUp) {
        dispatch(userExperienceActions.setChatBubbleBeenClosedOn(Date.now()))
      }
      setShouldAllowBarryIconOnDashboard(true)
      setIsOpen(true)
    }
  }, [dispatch, shouldShowBarryPopUp])

  useEffect(() => {
    const checkBarryDisplayInterval = setInterval(checkBarryDisplay, 250)
    const checkLocationChangeInterval = setInterval(checkLocationChange, 250)

    return () => {
      clearInterval(checkBarryDisplayInterval)
      clearInterval(checkLocationChangeInterval)
    }
  }, [checkBarryDisplay, checkLocationChange])

  const exitConvoIfExpired = useCallback(() => {
    const currentTime = new Date()
    if (chatEndsAt && currentTime > chatEndsAt) {
      dispatch(endChat())
      dispatch(pageActions.switchPage(Page.SPLASH_PAGE))
    }
  }, [chatEndsAt, dispatch])

  const checkIfChatHasEnded = useCallback(() => {
    const timerInterval = setInterval(() => {
      exitConvoIfExpired()
    }, 1000)
    return timerInterval
  }, [exitConvoIfExpired])

  useEffect(() => {
    exitConvoIfExpired()
    const timerInterval = checkIfChatHasEnded()

    return () => {
      clearInterval(timerInterval)
    }
  }, [isTabVisible, chatEndsAt, exitConvoIfExpired, checkIfChatHasEnded])

  useEffect(() => {
    const checkIfTabIsVisible = () => {
      setIsTabVisible(document.visibilityState === 'visible')
    }

    document.addEventListener('visibilitychange', checkIfTabIsVisible)

    return () => {
      document.removeEventListener('visibilitychange', checkIfTabIsVisible)
    }
  }, [])

  useEffect(() => {
    //gets the body
    const body = document.querySelector('body')
    //the goal here is to disable the scrolling behind the chat when its open,
    //to prevent the mobile's physical browser from scrolling behind it.
    if (body) {
      isOpen && isMobile ? body.classList.add('hide-scroll') : body.classList.remove('hide-scroll')
    }
  }, [isOpen, isMobile])

  /**
   * Toggles Barry and determines if closing Barry should additionally hide Barry, needed on dashboard widths in which Barry is only visible if opened,
   * or additionally log out the user, needed when the user logs out with an open chat enabled.
   */
  const handleBarryIconToggle = () => {
    if (isBarryOverlapping && isOpen) {
      setShouldAllowBarryIconOnDashboard(false)
    }
    if (!isBarryOverlapping) {
      setShouldAllowBarryIconOnDashboard(false)
    }
    if (hasUserJustLoggedOut) {
      dispatch(endChat())
      dispatch(pageActions.switchPage(Page.SPLASH_PAGE))
      setHasUserJustLoggedOut(false)
    }
    if (!isOpen && shouldShowBarryPopUp) {
      //If chatBubble is open, Oopening from icon should behave same as closing with X, so set value if not present
      dispatch(userExperienceActions.setChatBubbleBeenClosedOn(Date.now()))
    }
    setShowBarryHelloMessage(false)
    setIsOpen(isOpen => !isOpen)
  }

  /**
   * Either opens barry or closes popup message
   * @param action - Either closes popop message (click on close) or opens barry (click on text)
   */
  const handleBarryHelloMessage = (action: BarryHelloMessagAction) => {
    if (action === BarryHelloMessagAction.OPEN) {
      setIsOpen(true)
    }
    if (action === BarryHelloMessagAction.HIDE) {
      setShowBarryHelloMessage(false)
    }
    dispatch(userExperienceActions.setChatBubbleBeenClosedOn(Date.now()))
  }

  return (
    <>
      <IconButton
        sx={{
          opacity: getOpacity(
            isDashboard,
            isBarryOverlapping,
            shouldAllowBarryIconOnDashboard,
            isMobile,
          ),
          margin: 0,
          padding: 0,
          width: toggleButtonSize,
          height: toggleButtonSize,
          borderRadius: '50%',
          position: 'fixed',
          bottom: ['16px', '24px'],
          right: ['16px', '24px'],
          cursor: 'pointer',
          animation:
            showBarryHelloMessage &&
            isBarryHelloMessageSuccess &&
            shouldFetchBubbleMessage &&
            !isOpen
              ? `${wobble} 4.5s ease 0s infinite`
              : 'none',
          animationFillMode: 'forwards',
          pointerEvents:
            !shouldAllowBarryIconOnDashboard && isBarryOverlapping && isDashboard ? 'none' : 'auto',
          zIndex,
        }}
        onClick={handleBarryIconToggle}
        id="barry-toggle"
        data-testid="barry-toggle"
      >
        <Flex>{isOpen ? <PurpleEllipsis /> : <BarryButtonIcon />}</Flex>
      </IconButton>
      {shouldShowBarryPopUp && (
        <BarryPopUp
          barryMessage={barryHelloMessage?.body.cta}
          handleBarryHelloMessage={action => handleBarryHelloMessage(action)}
        />
      )}
      <Flex
        id="barry-window"
        data-testid="barry-window"
        sx={{
          margin: 0,
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          flexDirection: 'column',
          border: ({ colors }) => ['none', `1px solid ${colors?.white15}`],
          borderRadius: [0, '16px'],
          transitionProperty: 'opacity',
          transitionDuration: '125ms',
          opacity: isOpen ? 1 : 0,
          pointerEvents: isOpen ? 'auto' : 'none',
          position: 'fixed',
          height: ['100%', 'calc(100% - 96px)'],
          maxHeight: ['none', '800px'],
          width: ['100%', '360px', '360px'],
          bottom: [0, `84px`],
          right: [0, '24px'],
          backgroundColor: 'smokyBlack',
          justifyContent: 'center',
          overflow: 'hidden',
          zIndex,
        }}
      >
        <Flex
          sx={{
            height: mobileHeaderHeight,
            width: '100%',
            alignItems: 'center',
            justifyContent: ['space-between', 'center'],
            borderBottom: '1px solid',
            borderColor: 'borderFaint',
            flexShrink: 0,
            p: '16px',
          }}
        >
          <ControlDLogo />
          {isMobile && (
            <IconButton
              data-testid="barry-mobile-close-button"
              sx={{
                height: '48px',
                width: '48px',
                mr: '-15px',
              }}
              onClick={handleBarryIconToggle}
            >
              <MobileHeaderClose />
            </IconButton>
          )}
        </Flex>
        <Flex
          sx={{
            flex: 1,
            alignItems: 'center',
            // todo: make centring relative to whole window height ignoring mobile top bar,
            // but not overlapping with top bar
            // marginTop: [`-${mobileHeaderHeight}`, 0],
            // paddingTop: [mobileHeaderHeight, 0],
          }}
        >
          {page === Page.SPLASH_PAGE && <SplashScreen />}
          {page === Page.DESCRIBE_ISSUE_PAGE && <DescribeYourIssue />}
          {page === Page.CHAT_PAGE && (
            <BarryChatScreen handleBarryIconToggle={handleBarryIconToggle} />
          )}
          {page === Page.SUBMIT_TICKET && <SubmitTicketView />}
          {page === Page.TICKET_SENT && (
            <TicketSent handleBarryIconToggle={handleBarryIconToggle} />
          )}
        </Flex>
      </Flex>
    </>
  )
}
